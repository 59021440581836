import axios from "axios"

const user = JSON.parse(localStorage.getItem("user"))

export default {
  namespaced: true,
  state: {
    user: user,
    loggedIn: user ? true : false
  },
  actions: {
    async login({ commit }, req) {
      return axios
        .post(
          "auth/login",
          {},
          {
            auth: {
              username: req.username,
              password: req.password
            }
          }
        )
        .then(() => {
          localStorage.setItem("user", JSON.stringify(req))

          commit("loginSuccess", req)
          return Promise.resolve()
        })
        .catch((error) => {
          commit("loginFailure")
          return Promise.reject(error)
        })
    },
    logout({ commit }) {
      localStorage.removeItem("user")
      commit("logout")
    }
  },
  mutations: {
    loginSuccess(state, user) {
      state.loggedIn = true
      state.user = user
    },
    loginFailure(state) {
      state.loggedIn = false
      state.user = null
    },
    logout(state) {
      state.loggedIn = false
      state.user = null
    }
  }
}
