import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/Login.vue")
  },
  {
    path: "/logout",
    name: "Logout",
    component: () => import("@/views/Logout.vue")
  },
  {
    path: "*",
    name: "Root",
    component: () => import("@/views/layout/Main.vue"),
    redirect: "/setting/lotto-round",
    children: [
      {
        path: "/setting/lotto-type",
        name: "LottoType",
        component: () => import("@/views/LottoType.vue")
      },
      {
        path: "/setting/lotto-type/add",
        name: "LottoTypeAdd",
        component: () => import("@/views/LottoTypeForm.vue")
      },
      {
        path: "/setting/lotto-type/:id/edit",
        name: "LottoTypeEdit",
        component: () => import("@/views/LottoTypeForm.vue")
      },
      {
        path: "/setting/lotto-round",
        name: "LottoRound",
        component: () => import("@/views/LottoRound.vue")
      },
      {
        path: "/setting/lotto-round/:id",
        name: "LottoRoundForm",
        component: () => import("@/views/LottoRoundForm.vue")
      }
    ]
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach(async (to, from, next) => {
  const publicPages = ["/login"];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem("user");

  if (authRequired && !loggedIn) {
    return next("/login");
  }

  next();
});

export default router;
