import { extend, setInteractionMode } from "vee-validate";
import * as rules from "vee-validate/dist/rules";

export default {
  setupValidate() {
    setInteractionMode("lazy");

    extend("min_value", {
      ...rules.min_value,
      message: "ต้องไม่ต่ำกว่า {min}",
    });

    extend("max_value", {
      ...rules.max_value,
      message: "ต้องไม่มากกว่า {max}",
    });

    extend("min", {
      ...rules.min,
      message: "ต้องไม่ต่ำกว่า {length} อักษร",
    });

    extend("max", {
      ...rules.max,
      message: `ต้องไม่มากกว่า {length} อักษร`,
    });

    extend("required", {
      validate(value) {
        return {
          required: true,
          valid: ["", null, undefined].indexOf(value) === -1,
        };
      },
      computesRequired: true,
      message: "ต้องไม่เป็นค่าว่าง",
    });

    extend("alpha_num", {
      ...rules.alpha_num,
      message: "ต้องเป็นตัวอักษรหรือตัวเลขเท่านั้น",
    });

    extend("numeric", {
      ...rules.numeric,
      message: "ต้องเป็นตัวเลขเท่านั้น",
    });

    extend("integer", {
      ...rules.integer,
      message: "ต้องเป็นจำนวนเต็มเท่านั้น",
    });

    extend("double", {
      ...rules.double,
      message: "ต้องเป็นทศนิยมเท่านั้น",
    });

    extend("password_confirm", {
      params: ["target"],
      validate(value, { target }) {
        return value === target;
      },
      message: "รหัสผ่านไม่ตรงกัน",
    });

    extend("decimal", {
      validate: (value, { decimals = "*", separator = "." } = {}) => {
        if (value === null || value === undefined || value === "") {
          return {
            valid: false,
          };
        }
        if (Number(decimals) === 0) {
          return {
            valid: /^-?\d*$/.test(value),
          };
        }
        const regexPart = decimals === "*" ? "+" : `{1,${decimals}}`;
        const regex = new RegExp(
          `^[-+]?\\d*(\\${separator}\\d${regexPart})?([eE]{1}[-]?\\d+)?$`
        );

        return {
          valid: regex.test(value),
        };
      },
      message: "ต้องเป็นตัวเลขเท่านั้น",
    });

    extend("is", {
      ...rules.is,
      message: "ไม่ถูกต้อง",
    });

    Object.keys(rules).forEach((rule) => {
      extend(rule, rules[rule]);
    });
  },
  focusError(observer) {
    for (const key of Object.keys(observer.fields).sort()) {
      if (observer.fields[key].invalid) {
        observer.refs[key].$el.scrollIntoView({
          block: "center",
        });
        if (observer.refs[key].$el.getElementsByTagName("input")[0]) {
          observer.refs[key].$el.getElementsByTagName("input")[0].focus();
        }
        return;
      }
    }
  },
};
