import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";

import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import axios from "axios";
import VueAxios from "vue-axios";
import VueSweetalert2 from "vue-sweetalert2";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import isBetween from "dayjs/plugin/isBetween";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { CDataTable, CCollapse, CPagination } from "@coreui/vue";

import validate from "@/helpers/validate";
import setup from "@/helpers/setup";
import swal from "@/helpers/swal";
import * as input from "@/helpers/input";

setup.init();

dayjs.extend(isBetween);
dayjs.extend(customParseFormat);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Asia/Bangkok");

axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.interceptors.request.use(
  function(config) {
    let user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      const token = `${user.username}:${user.password}`;
      const encodedToken = Buffer.from(token).toString("base64");
      config.headers.Authorization = "Basic " + encodedToken;
    }

    return config;
  },
  function(err) {
    return Promise.reject(err);
  }
);

Vue.use(BootstrapVue, IconsPlugin);
Vue.use(VueAxios, axios);
Vue.use(VueSweetalert2);

Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);
Vue.component("CDataTable", CDataTable);
Vue.component("CCollapse", CCollapse);
Vue.component("CPagination", CPagination);

Vue.prototype.swal = swal;
Vue.prototype.focusError = validate.focusError;
Vue.prototype.$date = dayjs;

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
  methods: {
    onlyNumber: input.onlyNumber,
    onlyNumberAndDot: input.onlyNumberAndDot
  }
}).$mount("#app");
