import Vue from "vue";
import Vuex from "vuex";
import auth from "./auth";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    viewIndex: 0
  },
  mutations: {
    forceReload(state) {
      state.viewIndex++;
    }
  },
  actions: {
    forceReload({ commit }) {
      commit("forceReload");
    }
  },
  modules: {
    auth
  }
});
