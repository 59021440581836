import Swal from "sweetalert2";

const swal = {
  showLoading() {
    Swal.showLoading();
  },
  processing() {
    Swal.fire({
      html: `
                  <div class="spinner">
                  <div class="bounce1"></div>
                  <div class="bounce2"></div>
                  <div class="bounce3"></div>
                  </div>
              `,
      showConfirmButton: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
      allowEnterKey: false,
      customClass: {
        container: "processing-swal"
      },
      background: "transparent"
    });
  },
  swalError(message, callBack = null) {
    Swal.fire({
      title: "เกิดข้อผิดพลาด",
      text: message,
      icon: "error",
      confirmButtonText: "ตกลง"
    }).then(() => {
      if (callBack != null) {
        callBack();
      }
    });
  },
  async swalSuccess(message) {
    await Swal.fire({
      title: "สำเร็จ!",
      text: message,
      icon: "success",
      confirmButtonText: "ตกลง"
    });
  },
  async confirm(title, text) {
    let res = await Swal.fire({
      title: title,
      text: text,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ยกเลิก"
    });
    return res.isConfirmed;
  },
  catchError(err, callback = null) {
    if (err.response) {
      this.swalError(err.response.data.message, callback);
    } else {
      this.swalError(err.message, callback);
    }
  },
  close() {
    Swal.close();
  }
};

export default swal;
